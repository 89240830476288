@charset "UTF-8";
/* 余白 8の倍数 */
/*
top
*/
/* ==[ .myBlockMv ]==================================== */
.myBlockMv {
  position: relative;
  z-index: 10;
}
.myBlockMv__wrap, .myBlockMv__bg {
  position: relative;
}
.myBlockMv__bg {
  margin-top: -4vw;
  overflow: hidden;
  height: 69vw !important;
}
@media not all and (min-width: 768px) {
  .myBlockMv__bg {
    margin-top: 0;
    height: 90vw !important;
  }
}
.myBlockMv__title {
  width: 35em;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  padding: 0 15px;
  margin: 0 auto;
}
.myBlockMv__title.-en {
  width: 90em;
  transition: opacity 1s ease;
}
.myBlockMv__title.-en.-end {
  opacity: 0;
}
.myBlockMv__title--main {
  display: flex;
  align-items: baseline;
  /* margin-bottom: 60px; */
}
.myBlockMv__title--main.js-animationText .word {
  display: flex;
}
.myBlockMv__title--main.js-animationText .word .txt {
  font-size: calc(32px + 68 * (100vw - 320px) / 1600);
  font-weight: 700;
  /* letter-spacing: .06em;
  line-height: 1.16; */
  display: inline-block;
}
.myBlockMv__title--catch {
  font-size: calc(16px + 18 * (100vw - 320px) / 1600);
  line-height: 1.5;
}

.myBlockMovie {
  /*  padding-top: 60px; */
}
.myBlockMovie__img {
  width: 100%;
  height: 600px;
  position: absolute;
  right: 0;
  bottom: -300px;
  overflow: hidden;
  transition: 0.5s ease;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .myBlockMovie__img {
    height: auto;
    text-align: right;
    position: relative;
    bottom: auto;
    right: auto;
  }
}
.myBlockMovie__img::before {
  content: "";
  display: block;
  padding-top: 38.7096%;
}
@media not all and (min-width: 768px) {
  .myBlockMovie__img::before {
    display: none;
  }
}
.myBlockMovie__img img,
.myBlockMovie__img video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s ease;
}
@media not all and (min-width: 768px) {
  .myBlockMovie__img img,
.myBlockMovie__img video {
    position: static;
    top: auto;
    left: auto;
  }
}
.myBlockMovie__img img.pc,
.myBlockMovie__img video.pc {
  display: block;
}
@media not all and (min-width: 768px) {
  .myBlockMovie__img img.pc,
.myBlockMovie__img video.pc {
    display: none;
  }
}
.myBlockMovie__img img.sp,
.myBlockMovie__img video.sp {
  display: none;
}
@media not all and (min-width: 768px) {
  .myBlockMovie__img img.sp,
.myBlockMovie__img video.sp {
    display: block;
  }
}

/* ==[ .myBlockVision ]==================================== */
.myBlockVision {
  position: relative;
  z-index: 10;
}
.myBlockVision .sec-inner {
  padding-top: 480px;
}
@media not all and (min-width: 768px) {
  .myBlockVision .sec-inner {
    padding-top: 60px;
  }
}
.myBlockVision .modBtnBlock {
  text-align: center;
  margin-top: 50px;
}

/* ==[ .myBlockAnswer ]==================================== */
.myBlockAnswer {
  position: relative;
}
.myBlockAnswer__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s ease;
}
.myBlockAnswer__bg.-load {
  opacity: 0;
}
.myBlockAnswer__bg.-active {
  opacity: 1;
}
.myBlockAnswer__bg > div {
  width: 100%;
  height: 100%;
}
.myBlockAnswer .sec-inner {
  padding-bottom: 0;
}
.myBlockAnswer .modTitle {
  margin-bottom: 0;
}
@media not all and (min-width: 768px) {
  .myBlockAnswer .modTitle {
    margin-bottom: 20px;
  }
}
.myBlockAnswer .myBlockScroll {
  padding-top: 80px;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media not all and (min-width: 768px) {
  .myBlockAnswer .myBlockScroll {
    padding-top: 60px;
  }
}
.myBlockAnswer .myBlockScroll__container {
  padding-bottom: 128px;
}
@media not all and (min-width: 768px) {
  .myBlockAnswer .myBlockScroll__container {
    padding-bottom: 20px;
  }
}

/* ==[ .myBlockInterview ]==================================== */
.myBlockInterview .sliderBlock {
  padding-left: 15px;
  margin-bottom: 40px;
  width: calc(100% + (100vw - 1200px) / 2);
}
@media not all and (min-width: 1200px) {
  .myBlockInterview .sliderBlock {
    width: 100vw;
  }
}
@media not all and (min-width: 768px) {
  .myBlockInterview .sliderBlock {
    width: calc(100% - 15px);
  }
}
.myBlockInterview__slider .container-fluid {
  margin: 0;
  align-items: center;
}
.myBlockInterview .modParts__card {
  padding-bottom: 25vh;
}
@media not all and (min-width: 768px) {
  .myBlockInterview .modParts__card {
    padding-bottom: 0;
  }
}
.myBlockInterview .modParts__card--inner {
  background-color: white;
  padding: 30px;
  width: calc(100% - 60px);
  position: absolute;
  bottom: 0;
  right: 0;
}
@media not all and (min-width: 768px) {
  .myBlockInterview .modParts__card--inner {
    padding: 20px;
    width: 100%;
    position: static;
    bottom: auto;
    right: auto;
  }
}
.myBlockInterview .modParts__card--img {
  overflow: hidden;
  position: relative;
}
.myBlockInterview .modParts__card--img::before {
  content: "";
  display: block;
  padding-top: 125%;
}
.myBlockInterview .modParts__card--img img {
  height: 100%;
  width: 100%;
  transform: scale(1);
  transition: transform 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  .myBlockInterview .modParts__card--body {
    padding-bottom: 10px;
  }
}
.myBlockInterview .modParts__card--ttl {
  margin-bottom: 40px;
}
@media not all and (min-width: 768px) {
  .myBlockInterview .modParts__card--ttl {
    margin-bottom: 20px;
  }
}
.myBlockInterview .modParts__card:hover .modParts__card--img img {
  transform: scale(1.1);
}
.myBlockInterview .sliderControlBlock {
  display: flex;
  align-items: center;
  gap: 0 30px;
  justify-content: flex-end;
}
.myBlockInterview .sliderControl {
  display: flex;
  align-items: center;
  gap: 0 10px;
}
.myBlockInterview .swiper-button-prev,
.myBlockInterview .swiper-button-next {
  width: 50px;
  height: 50px;
  margin-top: 0;
  position: static;
  top: auto;
  left: auto;
  right: auto;
  z-index: 1;
  transition: 0.5 ease;
}
.myBlockInterview .swiper-button-prev::after,
.myBlockInterview .swiper-button-next::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
}
.myBlockInterview .swiper-button-prev::after {
  background: url(../img/common/slider_prev_off.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockInterview .swiper-button-prev:hover::after {
  background: url(../img/common/slider_prev_on.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockInterview .swiper-button-next::after {
  background: url(../img/common/slider_next_off.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockInterview .swiper-button-next:hover::after {
  background: url(../img/common/slider_next_on.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockInterview .modBtmBlock__sp .modBtn02 {
  margin: 0 auto;
}
.myBlockInterview .myBlockAnime {
  padding: 0 15px;
  height: 100vh;
  width: 700px;
  max-width: 100%;
  top: 0;
  left: 0;
  transform: translate(0, 0);
}
@media not all and (min-width: 768px) {
  .myBlockInterview .myBlockAnime__blob.-left {
    top: 0;
    left: -20%;
    transform: translate(0, 0);
  }
}
@media not all and (min-width: 768px) {
  .myBlockInterview .myBlockAnime__blob.-right {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/* ==[ .myBlockQuality ]==================================== */
.myBlockQuality {
  background-color: #fff;
  position: relative;
}
.myBlockQuality .sec-inner {
  padding-bottom: 180px;
}
@media not all and (min-width: 768px) {
  .myBlockQuality .sec-inner {
    padding-bottom: 60px;
  }
}
.myBlockQuality .bottomBlock {
  padding-top: 100px;
}
@media not all and (min-width: 768px) {
  .myBlockQuality .bottomBlock {
    padding-top: 40px;
  }
}
.myBlockQuality__chart {
  width: 100%;
  max-width: 100%;
  position: relative;
}
.myBlockQuality__chart--bg {
  text-align: center;
  margin: 0 auto;
  width: 55.8333333333vw;
  max-width: 670px;
  min-width: 240px;
  position: relative;
}
.myBlockQuality__chart--bg img {
  width: auto;
}
.myBlockQuality__chart--contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.myBlockQuality__chart--wrap {
  text-align: center;
  position: relative;
  width: auto;
}
.myBlockQuality__chart--wrap.-qty1 {
  width: 100%;
}
.myBlockQuality__chart--wrap.-qty2, .myBlockQuality__chart--wrap.-qty3 {
  margin-top: -4%;
}
.myBlockQuality__circle {
  border-radius: 100%;
  color: #121213;
  height: 38.3333333333vw;
  width: 38.3333333333vw;
  max-width: 460px;
  max-height: 460px;
  min-width: 150px;
  min-height: 150px;
  transform: scale(1);
  transition: transform 0.5s;
  overflow: hidden;
}
.myBlockQuality__circle:hover {
  opacity: 1;
  /* transform: scale(1.2); */
}
.myBlockQuality__circle:hover .modTitle__jp {
  color: #121213;
}
.myBlockQuality__circle--bg {
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}
.myBlockQuality__circle--container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px 0;
  flex-direction: column;
  overflow: hidden;
  height: 38.3333333333vw;
  width: 38.3333333333vw;
  max-width: 460px;
  max-height: 460px;
  min-width: 150px;
  min-height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
@media not all and (min-width: 768px) {
  .myBlockQuality__circle--container {
    gap: 10px 0;
  }
}
.myBlockQuality__circle .modTitle__jp {
  font-size: calc(14px + 14 * (100vw - 320px) / 1600);
  margin-bottom: 10px;
}
@media not all and (min-width: 768px) {
  .myBlockQuality__circle .modTitle__jp {
    margin-bottom: 10px;
  }
}
.myBlockQuality__circle .modTitle__en {
  font-size: calc(10px + 6 * (100vw - 320px) / 1600);
  color: #A6C8E0;
}
.myBlockQuality .custom-modal .modal-content {
  padding: 15px 0;
}
@media not all and (min-width: 768px) {
  .myBlockQuality .custom-modal .modal-header {
    padding-bottom: 0;
  }
}
.myBlockQuality .custom-modal .modal-header .modTitle__jp {
  margin-bottom: 20px;
}
@media not all and (min-width: 768px) {
  .myBlockQuality .custom-modal .modal-header .modTitle__jp {
    margin-bottom: 5px;
  }
}
.myBlockQuality .custom-modal .modal-header .modTitle__en {
  color: #A6C8E0;
  margin-bottom: 30px;
}
@media not all and (min-width: 768px) {
  .myBlockQuality .custom-modal .modal-header .modTitle__en {
    margin-bottom: 20px;
  }
}
.myBlockQuality .custom-modal .modal-body::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
.myBlockQuality .custom-modal .modal-body::-webkit-scrollbar-track {
  background: transparent;
}
.myBlockQuality .custom-modal .modal-body::-webkit-scrollbar-thumb {
  background: rgba(5, 86, 204, 0.5);
  border-radius: 5px;
}
.myBlockQuality .custom-modal .modal-footer {
  flex-direction: column;
  justify-content: center;
}

/* ==[ .myBlockCase ]==================================== */
.myBlockCase .sliderBlock {
  padding: 0 15px;
  overflow: hidden;
}
.myBlockCase .modBtmBlock__sp .modBtn02 {
  margin: 0 auto;
}
.myBlockCase .modCard {
  background-color: white;
  flex-direction: row;
  text-decoration: none;
}
@media not all and (min-width: 768px) {
  .myBlockCase .modCard {
    flex-direction: column;
  }
}
.myBlockCase .modCard .card-img {
  width: 40%;
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockCase .modCard .card-img {
    width: 100%;
  }
}
.myBlockCase .modCard .card-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  .myBlockCase .modCard .card-img img {
    position: static;
  }
}
.myBlockCase .modCard .card-inner {
  display: flex;
  flex-direction: column;
  gap: 50px 0;
  padding: 50px;
  width: 60%;
}
@media not all and (min-width: 768px) {
  .myBlockCase .modCard .card-inner {
    gap: 20px 0;
    padding: 30px 15px;
    width: 100%;
  }
}
.myBlockCase .modCard .card-body {
  color: #121213;
}
.myBlockCase .modCard .card-title {
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: 1.8;
  margin-bottom: 20px;
}
@media not all and (min-width: 768px) {
  .myBlockCase .modCard .card-title {
    margin-bottom: 10px;
  }
}

/* ==[ .myBlockNews ]==================================== */
.myBlockNews .modFlex {
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.myBlockNews .myBlockNews__sp {
  display: none;
  text-align: center;
}
@media not all and (min-width: 768px) {
  .myBlockNews .myBlockNews__sp {
    display: block;
    margin: 30px auto 0;
  }
}

/* ==[ .myBlockRecruit ]==================================== */
.myBlockRecruit .sec-inner {
  padding-top: 0;
}
@media not all and (min-width: 768px) {
  .myBlockRecruit .sec-inner {
    padding-bottom: 0;
  }
}
.myBlockRecruit__header {
  height: 560px;
  width: 100%;
}
@media not all and (min-width: 992px) {
  .myBlockRecruit__header {
    height: auto;
  }
}
.myBlockRecruit__header img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
}
@media not all and (min-width: 992px) {
  .myBlockRecruit__header img {
    height: auto;
    object-fit: unset;
  }
}
.myBlockRecruit .container-fluid {
  margin-top: -10vw;
}
@media not all and (min-width: 992px) {
  .myBlockRecruit .container-fluid {
    margin-top: 0;
  }
}
.myBlockRecruit .container-fluid__inner {
  padding: 80px;
}
@media not all and (min-width: 768px) {
  .myBlockRecruit .container-fluid__inner {
    padding: 30px 15px;
    margin: calc(50% - 50vw);
    width: 100vw;
  }
}
.myBlockRecruit .modTitle {
  margin-bottom: 30px;
}
.myBlockRecruit__btn {
  text-align: center;
  margin-top: 50px;
}
@media not all and (min-width: 768px) {
  .myBlockRecruit__btn {
    margin-top: 20px;
  }
}