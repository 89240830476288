@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;
@use "sass:math";

/*
top
*/

$PAGE_ID: top;
$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';
$IMAGE_PATH: '#{$PATH}img/#{$PAGE_ID}/';

/* ==[ .myBlockMv ]==================================== */
.myBlockMv {
  position: relative;
  z-index: 10;

  &__inner{

  }

  &__wrap,
  &__bg {
    position: relative;
  }

  &__bg {
    //height: 1040px;
    margin-top: -4vw;
    overflow: hidden;
    height: 69vw!important;
    // height: 100dvh;
    @include mixin.tabletP {
      margin-top: 0;
      height: 90vw!important;
    }
  }

  &__title {
    width: 35em;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    padding: 0 15px;
    margin: 0 auto;

    &.-en{
      width: 90em;
      transition:opacity 1s ease;
      &.-end{
        opacity: 0;
      }

    }

    &.-ja{

    }

    &--main {
      display: flex;
      align-items: baseline;
      /* margin-bottom: 60px; */

      &.js-animationText {
        .word {
          display: flex;

          .txt {
            font-size: calc(32px + 68 * (100vw - 320px) / 1600);
            font-weight: 700;
            /* letter-spacing: .06em;
            line-height: 1.16; */
            display: inline-block;
          }
        }
      }
    }

    &--catch {
      font-size: calc(16px + 18 * (100vw - 320px) / 1600);
      line-height: 1.5;
    }
  }
}

.myBlockMovie {
 /*  padding-top: 60px; */

  &__img {
    width: 100%;
    height: 600px;
    position: absolute;
    right: 0;
    bottom: -300px;
    overflow: hidden;
    transition: .5s ease;
    z-index: 1;

    @include mixin.tabletP {
      height: auto;
      text-align: right;
      position: relative;
      bottom: auto;
      right: auto;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 38.7096%;

      @include mixin.tabletP {
        display: none;
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: .5s ease;
      //object-fit: contain;

      @include mixin.tabletP {
        position: static;
        top: auto;
        left: auto;
      }

      &.pc {
        display: block;

        @include mixin.tabletP {
          display: none;
        }
      }

      &.sp {
        display: none;

        @include mixin.tabletP {
          display: block;
        }
      }
    }
  }

}

/* ==[ .myBlockVision ]==================================== */
.myBlockVision {
  position: relative;
  z-index: 10;

  .sec-inner {
    padding-top: 480px;

    @include mixin.tabletP {
      padding-top: 60px;
    }
  }

  .modBtnBlock {
    text-align: center;
    margin-top: 50px;
  }
}

/* ==[ .myBlockAnswer ]==================================== */
.myBlockAnswer {
  position: relative;

  &__bg {
    position: fixed;
    // top: 50%;
    // left: 0;
    // transform: translateY(-50%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // opacity: 0;
    transition:opacity 0.3s ease;

    &.-load{
      opacity: 0;
    }

    &.-active{
      opacity: 1;
    }

    >div{
      width: 100%;
      height: 100%;
    }

  }
  .sec-inner {
    padding-bottom: 0;
  }

  .modTitle {
    margin-bottom: 0;

    @include mixin.tabletP {
      margin-bottom: 20px;
    }
  }

  .myBlockScroll {
    padding-top: 80px;
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mixin.tabletP {
      padding-top: 60px;
    }



    &__container {
      padding-bottom: 128px;
      @include mixin.tabletP {
        padding-bottom: 20px;
      }
    }
  }
}

/* ==[ .myBlockInterview ]==================================== */
.myBlockInterview {
  .sliderBlock {
    padding-left: 15px;
    margin-bottom: 40px;
    width: calc(100% + (100vw - 1200px) / 2);

    @include mixin.pc {
      width: 100vw;
    }

    @include mixin.tabletP {
      width: calc(100% - 15px);
    }
  }

  &__slider {

    .container-fluid {
      margin: 0;
      align-items: center;
    }
  }

  .modParts__card {
    padding-bottom: 25vh;

    @include mixin.tabletP {
      padding-bottom: 0;
    }

    &--inner {
      background-color: white;
      padding: 30px;
      width: calc(100% - 60px);
      position: absolute;
      bottom: 0;
      right: 0;

      @include mixin.tabletP {
        padding: 20px;
        width: 100%;
        position: static;
        bottom: auto;
        right: auto;
      }
    }

    &--img {
      overflow: hidden;
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-top: 125%;
      }

      img {
        height: 100%;
        width: 100%;
        transform: scale(1);
        transition: transform .5s ease;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &--body {

      @include mixin.tabletP {
        padding-bottom: 10px;
      }
    }

    &--ttl {
      margin-bottom: 40px;

      @include mixin.tabletP {
        margin-bottom: 20px;
      }
    }

    &:hover {
      .modParts__card--img {

        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .sliderControlBlock {
    display: flex;
    align-items: center;
    gap: 0 30px;
    justify-content: flex-end;
  }

  .sliderControl {
    display: flex;
    align-items: center;
    gap: 0 10px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 50px;
    height: 50px;
    margin-top: 0;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    z-index: 1;
    transition: .5 ease;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
    }
  }

  .swiper-button-prev {

    &::after {
      background: url(#{mixin.set_common_path('slider_prev_off.svg')}) no-repeat center center;
      background-size: 100% auto;
    }

    &:hover {
      &::after {
        background: url(#{mixin.set_common_path('slider_prev_on.svg')}) no-repeat center center;
        background-size: 100% auto;
      }
    }
  }

  .swiper-button-next {

    &::after {
      background: url(#{mixin.set_common_path('slider_next_off.svg')}) no-repeat center center;
      background-size: 100% auto;
    }

    &:hover {
      &::after {
        background: url(#{mixin.set_common_path('slider_next_on.svg')}) no-repeat center center;
        background-size: 100% auto;
      }
    }
  }

  .modBtmBlock__sp {
    .modBtn02 {
      margin: 0 auto;
    }
  }

  .myBlockAnime {
    padding: 0 15px;
    height: 100vh;
    width: 700px;
    max-width: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 0);

    &__blob {

      &.-left {

        @include mixin.tabletP {
          top: 0;
          left: -20%;
          transform: translate(0, 0);
        }
      }

      &.-right {

        @include mixin.tabletP {
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

/* ==[ .myBlockQuality ]==================================== */
.myBlockQuality {
  background-color: #fff;
  position: relative;

  .sec-inner {
    padding-bottom: 180px;

    @include mixin.tabletP {
      padding-bottom: 60px;
    }
  }

  .bottomBlock {
    padding-top: 100px;

    @include mixin.tabletP {
      padding-top: 40px;
    }
  }

  &__chart {
    width: 100%;
    max-width: 100%;
    position: relative;

    &--bg {
      text-align: center;
      margin: 0 auto;
      width: calc(670 / 1200 * 100vw);
      max-width: 670px;
      min-width: 240px;
      position: relative;

      img {
        width: auto;
      }
    }

    &--contents {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--wrap {
      text-align: center;
      position: relative;
      width: auto;

      &.-qty1 {
        width: 100%;
      }

      &.-qty2,
      &.-qty3 {
        margin-top: -4%;
      }
    }
  }

  &__circle {
    border-radius: 100%;
    color: variables.$COLOR_TEXT;
    height: calc(460 / 1200 * 100vw);
    width: calc(460 / 1200 * 100vw);
    max-width: 460px;
    max-height: 460px;
    min-width: 150px;
    min-height: 150px;
    transform: scale(1);
    transition: transform .5s;
    overflow: hidden;

    &:hover {
      opacity: 1;
      /* transform: scale(1.2); */

      .modTitle__jp {
        color: variables.$COLOR_TEXT;
      }
    }

    &--bg {
      border-radius: 100%;
      position: relative;
      overflow: hidden;
    }

    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px 0;
      flex-direction: column;
      overflow: hidden;
      height: calc(460 / 1200 * 100vw);
      width: calc(460 / 1200 * 100vw);
      max-width: 460px;
      max-height: 460px;
      min-width: 150px;
      min-height: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;

      @include mixin.tabletP {
        gap: 10px 0;
      }
    }

    .modTitle {
      &__jp {
        font-size: calc(14px + 14 * (100vw - 320px) / 1600);
        margin-bottom: 10px;

        @include mixin.tabletP {
          margin-bottom: 10px;
        }
      }

      &__en {
        font-size: calc(10px + 6 * (100vw - 320px) / 1600);
        color: #A6C8E0;
      }
    }
  }

  .custom-modal {
    .modal-content {
      padding: 15px 0;
    }

    .modal-header {
      @include mixin.tabletP {
        padding-bottom: 0;
      }

      .modTitle__jp {
        margin-bottom: 20px;

        @include mixin.tabletP {
          margin-bottom: 5px;
        }
      }

      .modTitle__en{
        color: #A6C8E0;
        margin-bottom: 30px;

        @include mixin.tabletP {
          margin-bottom: 20px;
        }
      }
    }

    .modal-body {
      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(5, 86, 204, .5);
        border-radius: 5px;
      }
    }

    .modal-footer {
      flex-direction: column;
      justify-content: center;
    }
  }

}

/* ==[ .myBlockCase ]==================================== */
.myBlockCase {

  .sliderBlock {
    padding: 0 15px;
    overflow: hidden;
  }

  .modBtmBlock__sp {
    .modBtn02 {
      margin: 0 auto;
    }
  }

  .modCard {
    background-color: white;
    flex-direction: row;
    text-decoration: none;

    @include mixin.tabletP {
      flex-direction: column;
    }

    .card-img {
      width: 40%;
      position: relative;

      @include mixin.tabletP {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @include mixin.tabletP {
          position: static;
        }
      }
    }

    .card-inner {
      display: flex;
      flex-direction: column;
      gap: 50px 0;
      padding: 50px;
      width: 60%;

      @include mixin.tabletP {
        gap: 20px 0;
        padding: 30px 15px;
        width: 100%;
      }
    }

    .card-body {
      color: variables.$COLOR_TEXT;
    }

    .card-title {
      font-size: calc(18px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: 1.8;
      margin-bottom: 20px;

      @include mixin.tabletP {
        margin-bottom: 10px;
      }
    }
  }
}


/* ==[ .myBlockNews ]==================================== */
.myBlockNews {

  .modFlex {
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .myBlockNews__sp {
    display: none;
    text-align: center;

    @include mixin.tabletP {
      display: block;
      margin: 30px auto 0;
    }
  }
}

/* ==[ .myBlockRecruit ]==================================== */
.myBlockRecruit {
  .sec-inner {
    padding-top: 0;

    @include mixin.tabletP {
      padding-bottom: 0;
    }
  }

  &__header {
    height: 560px;
    width: 100%;

    @include mixin.tabletL {
      height: auto;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';

      @include mixin.tabletL {
        height: auto;
        object-fit: unset;
      }
    }
  }

  .container-fluid {
    margin-top: -10vw;

    @include mixin.tabletL {
      margin-top: 0;
    }

    &__inner {
      padding: 80px;

      @include mixin.tabletP {
        padding: 30px 15px;
        margin: calc(50% - 50vw);
        width: 100vw;
      }
    }
  }

  .modTitle {
    margin-bottom: 30px;
  }

  &__btn {
    text-align: center;
    margin-top: 50px;

    @include mixin.tabletP {
      margin-top: 20px;
    }
  }
}
