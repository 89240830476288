@use "sass:math";
@use "variables" as variables;

@mixin pc() {
  @media not all and (min-width: map_get(variables.$BRAKE_POINT, pc)) {
    @content;
  }
}

@mixin pc-min() {
  @media (min-width: map_get(variables.$BRAKE_POINT, pc)) {
    @content;
  }
}

@mixin tabletL() {
  @media not all and (min-width: map_get(variables.$BRAKE_POINT, tbL)) {
    @content;
  }
}

@mixin tabletL-min() {
  @media (min-width: map_get(variables.$BRAKE_POINT, tbL)) {
    @content;
  }
}

@mixin tabletP() {
  @media not all and (min-width: map_get(variables.$BRAKE_POINT, tbP)) {
    @content;
  }
}

@mixin tabletP-min() {
  @media (min-width: map_get(variables.$BRAKE_POINT, tbP)) {
    @content;
  }
}

@mixin sp() {
  @media not all and (min-width: map_get(variables.$BRAKE_POINT, sp)) {
    @content;
  }
}

@mixin sp-min() {
  @media (min-width: map_get(variables.$BRAKE_POINT, sp)) {
    @content;
  }
}

@mixin max($max) {
  @media not all and (min-width: #{$max + 1}px) {
    @content;
  }
}

@mixin min($min) {
  @media (min-width: #{$min}px) {
    @content;
  }
}

// font-size
@mixin fz($size: map_get(variables.$BASE, font-size), $important: false) {
  font-size: if($important, math.div($size, 16) + rem !important, math.div($size, 16) + rem);
}

@function get_vw($size, $viewport) {
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10, $viewport: 750) {
  font-size: get_vw($font_size, $viewport);
}

@mixin vw($property: font-size, $size: 10, $viewport: 750) {
  #{$property}: get_vw($size, $viewport);
}

@mixin vh($property: height,  $size: 100, $calc: 0px) {
  #{$property}: calc((#{$size + vh}) + #{$calc});
  #{$property}: calc(var(--vh, 1vh) * #{$size} + #{$calc});
}

@mixin animation($animation-name, $s, $easing, $count, $end: none, $delay: 0) {
  animation: {
    delay: $delay;
    duration: $s;
    fill-mode: $end;
    iteration-count: $count;
    name: $animation-name;
    timing-function: $easing;
  }
}

@mixin accessibilityHidden() {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// webFont icon
@mixin iconFont() {
  font-family: FontAwesome, serif;
  line-height: 1;
}

@mixin iconMaterial($size: 24) {
  direction: ltr;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
  line-height: 1;
  white-space: nowrap;
  word-wrap: normal;
  font: {
    family: variables.$FONT_ICON;
    feature-settings: 'liga';
    style: normal;
    weight: normal;
  }
  @include fz($size);
}

@function set_common_path($fileName, $path: '../') {
  @if ($fileName) {
    @return '#{$path}img/common/#{$fileName}';
  } @else {
    @error '$fileNameがありません。';
  }
}

@function set_image_path($fileName, $path: '../') {
  @if ($fileName) {
    @return '#{$path}img/#{$fileName}';
  } @else {
    @error '$fileNameがありません。';
  }
}
@mixin object-fit($value: fill, $position: null) {
  object-fit: $value;

  @if $position {
    font-family: 'object-fit: #{$value}; object-position: #{$position}', sans-serif;
    object-position: $position;
  } @else {
    font-family: 'object-fit: #{$value}', sans-serif;
  }
}

@mixin lineClamp($line: 1) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: #{$line};
  overflow: hidden;
}

/* 余白 8の倍数 */
@mixin multiple-p($position: null, $size: 1) {
  @if ($position) {
    padding-#{$position}: calc( 8px * #{$size} );
  } @else {
    padding: calc( 8px * #{$size} );
  }
}

@mixin multiple-m($position: null, $size: 1) {
  @if ($position) {
    margin-#{$position}: calc( 8px * #{$size} );
  } @else {
    margin: calc( 8px * #{$size} );
  }
}