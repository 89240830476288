// color
$COLOR: (
  blue: #e8f0ff,
	gray: #828282,
	red: #BE0032,
  orange: #EC632F,
) !default;

$COLOR_TEXT: #121213;
$COLOR_MAIN: #0556cc;

// base
$BASE: (
  font-size: calc(14px + 2 * (100vw - 320px) / 1600),
	line-height: 2,
) !default;

// brake-point
$BRAKE_POINT: (
  sp: 600px,
  tbP: 768px,
  tbL: 992px,
  pc: 1200px,
);

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1200px},
) !default;

// font-family
$FONT_BASE: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;
$FONT_ALPHA: 'Outfit', Georgia, Arial, Helvetica, sans-serif;
$FONT_MINCHO: "Ryumin Medium KL", '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';
$FONT_NUMBER: 'Barlow', sans-serif;
